import {nodeDetachedFromDocument} from 'jsdom/lib/jsdom/living/named-properties-window.js';

export default class Utils {
    static repeat (times, iterator) {
        return Array(times).fill('').map((val, index) => iterator(index)).join('');
    }

    static setCssVariable(css, name, value) {
        const cssString = String(css);
        if (cssString.indexOf(`${name}:`) > -1) {
            return cssString.replace(new RegExp(`${name}:.*?(;|})`), `${name}: ${value}$1`);
        } else {
            return `* {${name}: ${value};} ${cssString}`;
        }
    }

    static classes(...list) {
        let classNames = [];
        let className;
        while (className = list.shift()) {
            if (!!list.shift()) classNames.push(className);
        }
        return classNames.join(' ');
    }

    static range(start, stop, step) {
        if (stop === null) {
            stop = start || 0;
            start = 0;
        }
        if (!step) {
            step = stop < start ? -1 : 1;
        }

        const length = Math.max(Math.ceil((stop - start) / step), 0);
        const range = Array(length);

        for (let idx = 0; idx < length; idx++, start += step) {
            range[idx] = start;
        }

        return range;
    }

    static isBetween (num1, num2, num3) {
        if (num2 > num3) {
            return num1 <= num2 && num1 >= num3;
        } else {
            return num1 >= num2 && num1 <= num3;
        }
    }

    static makeDataUrl(data) {
        return `data:image/svg+xml,${encodeURIComponent(data)}`;
    }

    static xmlToObject(xmlString, options = {}) {
        const { arrays = [], exclude = [], booleans = [], numbers = [], preserveCapitals = false } = options;

        function formatName(name) {
            return preserveCapitals ? name : name.charAt(0).toLowerCase() + name.slice(1);
        }

        function convertType(name, value) {
            if (booleans.includes(name)) {
                return value.toLowerCase() === 'true';
            }
            if (numbers.includes(name)) {
                return Number(value);
            }
            return value;
        }

        const parser = new DOMParser();
        let dom;
        try {
            dom = parser.parseFromString(xmlString, 'application/xml');
        } catch (error) {
            console.log('ERROR: XmlToObject failed to parse XML');
            return null;
        }

        function parseNode(xmlNode, result) {
            let nodeName = xmlNode.nodeName;
            if (nodeName === "#text") {
                let v = xmlNode.nodeValue;
                if (v.trim()) result['#text'] = v;
                return;
            }

            if (exclude.indexOf(nodeName) !== -1) {
                return;
            }

            name = formatName(nodeName);

            let jsonNode = {},
                existing = result[name];
            if (existing) {
                if (!Array.isArray(existing)) result[name] = [existing, jsonNode];
                else result[name].push(jsonNode);
            }
            else {
                if (arrays.indexOf(nodeName) !== -1) result[name] = [jsonNode];
                else result[name] = jsonNode;
            }

            if (xmlNode.attributes) for (let attribute of xmlNode.attributes) jsonNode[formatName(attribute.nodeName)] = convertType(attribute.nodeName, attribute.nodeValue);

            for (let node of xmlNode.childNodes) parseNode(node, jsonNode);
        }

        let result = {};
        for (let node of dom.childNodes) parseNode(node, result);

        return result;
    }

    static doAndResizeSmoothly(element, action, endAnimationCallback = () => {}) {
        const startWidth = element.offsetWidth;
        const startHeight = element.offsetHeight;
        action();
        const childElement = element.firstElementChild;
        const childRect = childElement.getBoundingClientRect();
        const endWidth = element.offsetWidth;
        const endHeight = element.offsetHeight;
        const styleWidth = element.style.width;
        const styleHeight = element.style.height;
        const childStyleWidth = childElement.style.width;
        const childStyleHeight = childElement.style.height;
        const styleOverflow = element.style.overflow;
        element.style.overflow = 'hidden';
        element.style.width = startWidth + 'px';
        element.style.height = startHeight + 'px';
        element.style.transition = 'width 200ms, height 200ms';
        childElement.style.width = childRect.width + 'px';
        childElement.style.height = childRect.height + 'px';
        setTimeout(() => {
            element.style.width = endWidth + 'px';
            element.style.height = endHeight + 'px';
        });
        setTimeout(() => {
            element.style.width = styleWidth;
            element.style.height = styleHeight;
            element.style.overflow = styleOverflow;
            element.style.transition = 'none';
            childElement.style.width = childStyleWidth;
            childElement.style.height = childStyleHeight;
            endAnimationCallback();
        }, 201);
    }
}
