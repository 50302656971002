import { Model, Utils } from 'myassays-global';

export const operations = {
    ADD: 'add',
    SUBTRACT: 'subtract',
    MULTIPLY: 'multiply',
    DIVIDE: 'divide',
}
export const operationsModes = {
    BOTH: 'op-mode-both',
    ADD_SUBTRACT: 'op-mode-add-subtract',
    MULTIPLY_DIVIDE: 'op-mode-multiply-divide',
}

const template = id => `\
<style>
    input[name="operation"]+label {
      font-size: 1.5em;
      line-height: 1em;
      height: 30px;
      padding: 0;
    }
</style>
<div>
    <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" name="open-series-editor" id="${id}open-series-editor">
        <label class="form-check-label" for="${id}open-series-editor">Series</label>
    </div>
</div>
<div class="editor-controls">
    <div class="btn-group">
        <input type="radio" name="operation" value="${operations.ADD}" class="btn-check" id="${id}${operations.ADD}">
        <label class="btn btn-sm btn-outline-primary" for="${id}${operations.ADD}">&plus;</label>
        <input type="radio" name="operation" value="${operations.SUBTRACT}" class="btn-check" id="${id}${operations.SUBTRACT}">
        <label class="btn btn-sm btn-outline-primary" for="${id}${operations.SUBTRACT}">&minus;</label>
        <input type="radio" name="operation" value="${operations.MULTIPLY}" class="btn-check" id="${id}${operations.MULTIPLY}">
        <label class="btn btn-sm btn-outline-primary" for="${id}${operations.MULTIPLY}">&times;</label>
        <input type="radio" name="operation" value="${operations.DIVIDE}" class="btn-check" id="${id}${operations.DIVIDE}">
        <label class="btn btn-sm btn-outline-primary" for="${id}${operations.DIVIDE}">&divide;</label>
   </div>
    <input class="e-input" name="series-operand" type="text"/>
</div>`;


export default class SeriesEditorView extends EventTarget {
    static get uniqueId() {
        if (this._uniqueIdCounter === undefined) {
            this._uniqueIdCounter = 1;
        } else {
            this._uniqueIdCounter++;
        }
        return `--series-editor-view-${this._uniqueIdCounter}-`;
    }
    static events = {
        STATE_CHANGE: 'SeriesEditorView.statechange',
    }
    static defaultState = {
        open: undefined,
        operation: undefined,
        operatorMode: undefined,
        addSubtractOperand: undefined,
        multiplyDivideOperand: undefined,
    }
    constructor(rootElement, open, operation, operatorMode, addSubtractOperand, multiplyDivideOperand) {
        super();
        this._root = rootElement;
        this._root.innerHTML = template(SeriesEditorView.uniqueId);
        this._root.classList.add(`series-editor`);
        this._state = new Model(SeriesEditorView.defaultState);
        this._state.addEventListener('change', this.onStateChange);

        this._state.set({
            open,
            operation,
            operatorMode,
            addSubtractOperand,
            multiplyDivideOperand,
        });
        this.addControlListeners();
    }

    addControlListeners() {
        this._root.querySelector('[name="open-series-editor"]').addEventListener('change', this.onOpenChange);
        Array.from(this._root.querySelectorAll('[name="operation"]')).forEach(elem => {
            elem.addEventListener('click', this.onOperationClick)
        });
        const operand = this._root.querySelector('[name="series-operand"]');
        operand.addEventListener('change', this.onOperandChange);
        operand.addEventListener('blur', this.onOperandChange);
        operand.addEventListener('input', this.onOperandInput);
    }

    onOperationClick = evt => {
        const value = evt.target.value;
        this._state.set({operation: value});
    }

    onOpenChange = evt => {
        const editorControls = this._root.querySelector(`.editor-controls`);
        Utils.doAndResizeSmoothly(this._root, () => {
            this.toggleOpen();
        });
    }

    toggleOpen() {
        this._state.set({open: !this._state.get('open')});
    }

    onOperandChange = evt => {
        let valid = true;
        const op = this._state.get('operation');
        const attrName = (op === operations.ADD || op === operations.SUBTRACT ? 'addSubtractOperand' : 'multiplyDivideOperand');
        const minValue = (attrName === 'addSubtractOperand' ? 0 : 1);
        const { value } = evt.target;
        const numericValue = parseFloat(value);
        if (isNaN(numericValue) || numericValue < minValue) {
            valid = false;
        }
        evt.target.classList.toggle(`invalid`, !valid);
        if (valid) {
            this._state.set({[attrName]: numericValue});
            evt.target.value = numericValue;
        } else {
            evt.target.focus();
        }
    }

    onOperandInput = evt => {
        evt.target.classList.toggle(`invalid`, false);
    }

    onStateChange = evt => {
        const toggleClass = (selector, className, force) => {
            this._root.querySelector(selector).classList.toggle(className, force);
        }
        const updateOperandValue = () => {
            const op = this._state.get('operation');
            const operandValue = this._state.get(op === operations.ADD || op === operations.SUBTRACT ? 'addSubtractOperand' : 'multiplyDivideOperand');
            const operand = this._root.querySelector('[name="series-operand"]');
            operand.value = operandValue;
        }
        this._state.hasChanged('open', newValue => {
            const openButton = this._root.querySelector('[name="open-series-editor"]');
            const editorControls = this._root.querySelector(`.editor-controls`);
            editorControls.classList.toggle(`open`, newValue);
            openButton.toggleAttribute('checked', newValue);
        });
        this._state.hasChanged('operatorMode', newValue => {
            toggleClass(`[value="${operations.ADD}"]`, `hidden`, newValue === operationsModes.MULTIPLY_DIVIDE);
            toggleClass(`[value="${operations.SUBTRACT}"]`, `hidden`, newValue === operationsModes.MULTIPLY_DIVIDE);
            toggleClass(`[value="${operations.MULTIPLY}"]`, `hidden`, newValue === operationsModes.ADD_SUBTRACT);
            toggleClass(`[value="${operations.DIVIDE}"]`, `hidden`, newValue === operationsModes.ADD_SUBTRACT);
        });
        this._state.hasChanged('addSubtractOperand', newValue => {
            updateOperandValue();
        });
        this._state.hasChanged('multiplyDivideOperand', newValue => {
            updateOperandValue();
        });
        this._state.hasChanged('operation', (newValue, previousValue) => {
            [operations.ADD, operations.SUBTRACT, operations.MULTIPLY, operations.DIVIDE].forEach(name => {
                this._root.querySelector(`[value="${name}"]`).toggleAttribute('checked', name === newValue);
            });
            updateOperandValue();

            if (previousValue !== undefined) {
                const operand = this._root.querySelector('[name="series-operand"]');
                operand.select();
            }
        });

        this.dispatchEvent(new CustomEvent(SeriesEditorView.events.STATE_CHANGE, {
            detail: this._state,
        }));
    }

    get state() {
        return this._state.attributes;
    }

    setState(attributes) {
        this._state.set(attributes);
    }
}
