import Model from '../Model.js';

const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
class BlazorCompatibleEvent extends CustomEvent {
    constructor(type, options) {
        super(type, {...options, bubbles: true});
    }
}

export const dataTypes = {
    LABEL: 'label',                             // name label
    LABEL_WITH_TYPE: 'label-with-type',         // name label with type icon
    STRING: 'string',                           // any string
    NUMERIC: 'numeric',                         // numeric only
}

export const editModes = {
    NONE:  'none',              // no cells editable
    ALL: 'all',                 // all cells editable
    FIRST_CELL: 'first-cell',   // only first cell editable
}

const template = state => {
    const showPasteButtonRow = state.showPasteButtons && state.columns.filter(column => (column.editMode === editModes.ALL || column.editMode === editModes.FIRST_CELL)).length > 0;
    const getClasses = (column, col, section) => `${section} ${column.dataType} ${col === 0 ? `first` : ''}`;

    return `\
<table class="table table-sm ${state.selectable ? `selectable` : ''}">
    <thead>
        <tr>
        ${state.columns.map((column, col) => `\
            <th class="header ${column.dataType}">${column.heading || '&nbsp;'}</th>`).join('')}
        </tr>
    </thead>
    <tbody>
    ${state.items.map((item, row) => `<tr>${
        state.columns.map((column, col) => {
            const classes = `detail ${column.dataType} ${row === state.selectedRowIndex ? `selected bg-primary-subtle text-primary-emphasis` : ''}`;
            if (
                column.editMode === editModes.ALL ||
                (column.editMode === editModes.FIRST_CELL && row === 0)
            ) {
                // editable cell
                return `<td class="${classes} editable" data-row="${row}"><span class="label">${item[column.name] || '&nbsp;'}</span><input class="table-cell-input" type="text" size="1" data-row="${row}" data-name="${column.name}" value="${item[column.name]}"/></td>`;
            } else if (
                column.dataType === dataTypes.LABEL_WITH_TYPE
            ) {
                // display only cell with type icons
                return `<td class="${classes} display-only" data-row="${row}"><span class="icon" style="background-color: ${state.typeColour}">&nbsp;</span><span class="label">${String(item[column.name]).trim() === '' ? '&nbsp;' : item[column.name]}</span></td>`;
            } else {
                // display only cell
                return `<td class="${classes} display-only" data-row="${row}"><span class="label">${String(item[column.name]).trim() === '' ? '&nbsp;' : item[column.name]}</span></td>`;
            }
        }).join('')
    }</tr>`).join('')}
    ${showPasteButtonRow ? `<tr>${state.columns.map((column, col) => `\
        <td class="footer">${column.editMode === editModes.ALL || column.editMode === editModes.FIRST_CELL ? `<button class="btn btn-sm btn-primary paste ${state.pasteAllowed ? '' : 'greyed-out'}" data-column-name="${column.name}">Paste</button>` : '&nbsp;'}</td>`).join('')}</tr>` : ''}
    </tbody>
</table>`;
}


export default class ParamsTableView extends EventTarget {
    static dataTypes = dataTypes
    static editModes = editModes
    static events = {
        STATE_CHANGE: 'ParamsTableView.statechange',
        CELL_CHANGE: 'ParamsTableView.cellchange',
        CELL_PASTE: 'ParamsTableView.cellpaste',
        ERROR: 'ParamsTableView.error',
    }

    static errorCodes = {
        PASTE_NOT_ALLOWED: 'paste-not-allowed',
        PASTE_INVALID_DATA_TYPE: 'paste-invalid-data-type',
        PASTE_MISMATCHED_ROWS: 'paste-mismatched-rows',
        INVALID_ATTRIBUTE: 'invalid-attribute',
    }

    static defaultState = {
        selectable: false,      // if true, clicking on a cell will select that row
        showPasteButtons: true, // if true, any editable columns will have a paste button below
        pasteAllowed: !!navigator && !!navigator.clipboard && !!navigator.clipboard.readText,
        selectedRowIndex: -1,   // index of selected row
        columns: [],            // column config {name, heading, dataType, editMode}
        typeColour: '',         // colour of icon
        items: [],              // the row data
    }

    static get options() {
        class Options {
            _options = {
                items: [],
                selectable: false,
                selectedRowIndex: -1,
                showPasteButtons: true,
            };
            rootElement(value) {
                this._options.rootElement = value;
                return this;
            }
            columns(value) {
                this._options.columns = value;
                return this;
            }
            typeColour(value) {
                this._options.typeColour = value;
                return this;
            }
            items(value) {
                this._options.items = value;
                return this;
            }
            selectable(value) {
                this._options.selectable = value;
                return this;
            }
            selectedRowIndex(value) {
                this._options.selectedRowIndex = value;
                return this;
            }
            showPasteButtons(value) {
                this._options.showPasteButtons = value;
                return this;
            }

            createInstance() {
                return new ParamsTableView(this._options);
            }
        }
        return new Options();
    }

    constructor(options) {
        super();
        const { rootElement, columns, typeColour, items, selectable, selectedRowIndex, showPasteButtons } = options;
        this._root = rootElement;
        this._state = new Model(ParamsTableView.defaultState);
        this._state.addEventListener('change', this.onStateChange);
        this._state.set({
            selectable,
            showPasteButtons,
            selectedRowIndex,
            columns,
            typeColour,
            items,
        });
    }

    render() {
        const state = this.state;
        this._root.innerHTML = template(state);
        this.addControlListeners();
    }

    addControlListeners() {
        this._root.querySelectorAll('.table input').forEach(input => {
            input.addEventListener('focus', this.onCellFocus);
            input.addEventListener('blur', this.onCellBlur);
            input.addEventListener('change', this.onCellChange);
            input.addEventListener('input', this.onCellInput);
            input.addEventListener('keydown', this.onCellKeyDown);
            input.addEventListener('paste', this.onCellPaste);
        });
        if (this._state.get('selectable')) {
            this._root.querySelectorAll('.table .display-only').forEach(input => {
                input.addEventListener('click', this.onDisplayOnlyClick);
            });
        }
        if (this._state.get('showPasteButtons')) {
            this._root.querySelectorAll('button.paste').forEach(button => {
                button.addEventListener('focus', this.onPasteFocus);
                button.addEventListener('click', this.onPasteClick);
            });
        }
    }

    onPasteFocus = evt => {
        this._currentCell = null;
        const { relatedTarget } = evt;
        if (relatedTarget && relatedTarget.classList.contains(`table-cell-input`)) {
            this._currentCell = relatedTarget;
        }
    }

    onPasteClick = evt => {
        const { target } = evt;
        const columnName = target.getAttribute('data-column-name');
        this.pasteIntoColumn(columnName);
    }

    pasteIntoColumn(columnName) {
        if (this._state.get('pasteAllowed')) {
            const row = this._currentCell && this._currentCell.getAttribute('data-name') === columnName ? Number(this._currentCell.getAttribute('data-row')) : null;
            this._currentCell = null;
            navigator.clipboard
                .readText()
                .then(clipText => {
                    try {
                        this.pasteText(clipText, columnName, row);
                    } catch (error) {
                        this.dispatchErrorEvent(error);
                    }
                });
        } else {
            this.dispatchErrorEvent(Error(`Not available in this browser, please select a cell and paste using ${isMacLike ? 'Cmd' : 'Ctrl'}-V`, {cause: {code: ParamsTableView.errorCodes.PASTE_NOT_ALLOWED}}));
        }
    }

    pasteText(text, columnName, row = 0) {
        const { columns, items } = this._state.attributes;
        const column = columns.find(column => column.name === columnName);
        const pastedItems = text.trim().split(/ *\n */).map(line => line.split(/ *\t */));

        // data should be of correct type
        if (column.dataType === dataTypes.NUMERIC) {
            pastedItems.forEach(row => row.forEach((item, i, array) => {
                const numberValue = Number(item);
                if (isNaN(numberValue)) {
                    throw Error('Clipboard data should be numeric', {cause: {code: ParamsTableView.errorCodes.PASTE_INVALID_DATA_TYPE}});
                }
                array[i] = numberValue;
            }));
        }

        const cellChanges = [];
        pastedItems.forEach((rowItem, r) => {
            const rowIndex = row + r;
            const item = items[rowIndex];
            if (item && !(column.editMode === editModes.FIRST_CELL && r > 0)) {
                const previousValue = item[columnName];
                const value = pastedItems[r][0];
                const newValue = column.dataType === dataTypes.NUMERIC ? Number(value) : value;
                item[columnName] = newValue;
                cellChanges.push({
                    rowIndex,
                    columnName,
                    newValue,
                    previousValue,
                })
            }
        });
        this.items = items;
        cellChanges.forEach(change => {
            this.dispatchEvent(new CustomEvent(ParamsTableView.events.CELL_CHANGE, {
                detail: change,
            }));
        })
    }

    onCellPaste = evt => {
        evt.preventDefault();
        const { target, clipboardData } = evt;
        const row = Number(target.getAttribute('data-row'));
        const name = target.getAttribute('data-name');
        const clipboardText = (clipboardData || window.clipboardData).getData('text');
        const eventToDispatch = new CustomEvent(ParamsTableView.events.CELL_PASTE, {
            detail: {
                row,
                name,
                clipboardText,
            }
        });
        this.dispatchEvent(eventToDispatch);
        if (!eventToDispatch.defaultPrevented) {
            try {
                this.pasteText(clipboardText, name, row);
            } catch (error) {
                this.dispatchErrorEvent(error);
            }
        }
    }

    dispatchErrorEvent(error) {
        const event = new BlazorCompatibleEvent(ParamsTableView.events.ERROR, {
            detail: error,
            cancelable: true,
        });
        this.dispatchEvent(event) && alert(error.message);
    }

    _changeCell(name, row, value) {
        const items = this.items;
        const input = this._root.querySelector(`input[data-name="${name}"][data-row="${row}"]`);
        const dataType = this._state.get('columns').find(item => item.name === name).dataType;
        const previousValue = items[row][name];
        if (dataType === dataTypes.NUMERIC) {
            const numericValue = Number(value);
            if (isNaN(numericValue)) {
                input && (input.value = this.items[row][name]);
            } else {
                items[row][name] = numericValue;
                this.items = items;
                this.dispatchEvent(new CustomEvent(ParamsTableView.events.CELL_CHANGE, {
                    detail: {
                        rowIndex: row,
                        columnName: name,
                        newValue: numericValue,
                        previousValue,
                    },
                }));
                input && (input.value = numericValue);
            }
        } else {
            items[row][name] = value;
            this.items = items;
            this.dispatchEvent(new CustomEvent(ParamsTableView.events.CELL_CHANGE, {
                detail: {
                    rowIndex: row,
                    columnName: name,
                    newValue: value,
                    previousValue,
                },
            }));
        }
    }

    onCellChange = evt => {
        this.onCellInput(evt);
        const input = evt.target;
        const value = evt.target.value;
        const row = Number(input.getAttribute('data-row'));
        const name = input.getAttribute('data-name');
        setTimeout(() => {
            const focussedCell = this._focussedCell;

            this._changeCell(name, row, value);

            if (focussedCell) {
                const input = this._root.querySelector(`input[data-row="${focussedCell.row}"][data-name="${focussedCell.name}"]`);
                if (input) {
                    input.focus();
                    input.select();
                }
            }
        }, 1);
    }

    onCellInput = evt => {
        const input = evt.target;
        const cell = input.parentElement;
        const span = cell.querySelector(`span.label`);
        span.innerHTML = (input.value.replace(/ /g, '&nbsp;') || '&nbsp;');
    }

    onCellFocus = evt => {
        const input = evt.target;
        const row = Number(input.getAttribute('data-row'));
        const name = input.getAttribute('data-name');
        this._focussedCell = {row, name};
        input.select();
        if (this._state.get('selectable')) {
            this._state.set({selectedRowIndex: Number(input.getAttribute('data-row'))});
        }
    }

    onCellBlur = evt => {
        this._focussedCell = undefined;
    }

    onCellKeyDown = evt => {
        const { target, code } = evt;
        const length = this._state.get('items').length;
        const firstEditableColumnData = this._state.get('columns').find(item => item.editMode === editModes.ALL);
        const firstEditableColumnName = firstEditableColumnData ? firstEditableColumnData.name : undefined;
        const row = Number(target.getAttribute('data-row'));
        const name = target.getAttribute('data-name');
        let newRow = row;
        let newName = name;
        switch (code) {
            case 'ArrowUp':
                newRow = Math.max(row - 1, 0);
                break;
            case 'ArrowDown':
                newRow = Math.min(row + 1, length - 1);
                break;
            case 'Enter':
                newRow = Math.min(row + 1, length - 1);
                if (newRow > row && firstEditableColumnName) {
                    newName = firstEditableColumnName;
                }
                break;
            default:
                return;
        }
        evt.preventDefault();
        const nextCell = this._root.querySelector(`input[data-row="${newRow}"][data-name="${newName}"]`);
        target.blur();
        nextCell.focus();
        nextCell.select();
    }

    onDisplayOnlyClick = evt => {
        const cell = evt.target;
        this._state.set({selectedRowIndex: Number(cell.getAttribute('data-row'))});
    }

    onStateChange = evt => {
        this._state.hasChanged('columns', newValue => {
            this.render();
        });
        this._state.hasChanged('items', newValue => {
            this.render();
        });
        this._state.hasChanged('selectedRowIndex', newValue => {
            Array.from(this._root.querySelectorAll('td.selected')).forEach(cell => {
                cell.classList.toggle('selected', false);
                cell.classList.toggle('bg-primary-subtle', false);
                cell.classList.toggle('text-primary-emphasis', false);
            });
            Array.from(this._root.querySelectorAll(`td[data-row="${newValue}"]`)).forEach(cell => {
                cell.classList.toggle('selected', true);
                cell.classList.toggle('bg-primary-subtle', true);
                cell.classList.toggle('text-primary-emphasis', true);
            });
        });

        this.dispatchEvent(new CustomEvent(ParamsTableView.events.STATE_CHANGE, {
            detail: this._state,
        }));
    }

    get state() {
        return this._state.attributes;
    }

    set state(attributes) {
        this._state.set(attributes);
    }

    get items() {
        return this._state.get('items').map(item => ({...item}));
    }

    set items(value) {
        this._state.set({items: value});
    }

    set columns(value) {
        this._state.set({columns: value});
    }

    setEditMode(columnsName, value) {
        this._state.set({columns: this._state.get('columns').map(item => item.name === columnsName ? {...item, editMode: value} : item)});
    }
}
