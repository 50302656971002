import MyassaysParamsGroupTable from './MyassaysParamsGroupTable';
import styles from './MyassaysParamsGroupTable.css?raw';

document.write(`
<style>
${styles}
</style>
`);

customElements.define('myassays-params-group-table', MyassaysParamsGroupTable);

if (window.MyAssaysParamsGroupTable !== undefined) {
    if (!(window.MyAssaysParamsGroupTable instanceof Object)) {
        throw new Error('Could not create "window.MyAssaysParamsGroupTable" namespace.');
    }
} else {
    window.MyAssaysParamsGroupTable = {};
}
window.MyAssaysParamsGroupTable = {
    ...window.MyAssaysParamsGroupTable,
    getById: function (id) {
        const elem = document.getElementById(id);
        if (!elem) {
            throw `An instance of MyassaysParamsGroupTable with the id "${id}" could not be found.`;
        } else if (! elem instanceof MyassaysParamsGroupTable) {
            throw `The element with the id "${id}" is not a MyassaysParamsGroupTable.`;
        } else {
            return elem;
        }
    },
    getNameValue: function (id) {
        return this.getById(id).nameValue;
    },
    setNameValue: function (id, newValue) {
        this.getById(id).nameValue = newValue;
    },
    getDataValue: function (id) {
        return this.getById(id).dataValue;
    },
    setDataValue: function (id, newValue) {
        this.getById(id).dataValue = newValue;
    },
    getGroupMergeReplicates: function(id) {
        return this.getById(id).groupMergeReplicates;
    }
}
