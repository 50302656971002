import SeriesEditorView, {operations, operationsModes} from 'myassays-global/views/SeriesEditorView';
import ParamsTableView, {dataTypes, editModes} from 'myassays-global/views/ParamsTableView';
import { MyassaysComponent, Model, PropTypes, Utils } from 'myassays-global';

const groupsModes = {
    NORMAL: 'normal',
    IDS: 'ids',
}
const pasteButtonPositions = {
    RIGHT: 'right',
    BOTTOM: 'bottom',
}
const DECIMAL_SEPARATOR_POINT = '.';
const DECIMAL_SEPARATOR_COMMA = ',';

const NULL_NAME_BASE = '__nullNameBase__';

class BlazorCompatibleEvent extends CustomEvent {
    constructor(type, options) {
        super(type, {...options, bubbles: true});
    }
}

const template = (id, state) => {
    return `\
<div class="params-table border rounded"></div>
<div class="controls">
    <div class="units-editor ${state.unitsEditor ? `show` : ''}">
        <label class="editor-controls open" for="${id}units">Units:</label>
        <input id="${id}units" name="units" type="text" />
    </div>
    <div class="series-editor-root"></div>
    <div class="series-repeat-editor ${state.groupsMode !== groupsModes.IDS && state.seriesRepeatEditor ? `show` : ''}">
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" name="open-series-repeat-editor" id="${id}open-series-repeat-editor">
            <label class="form-check-label" for="${id}open-series-repeat-editor">Repeat</label>
        </div>
        <div class="editor-controls">
            <input name="repeat" type="text" />
        </div>
    </div>
    <div class="replicates-editor ${state.groupMerge ? `show` : ''}">
        <label class="editor-controls open" for="${id}replicates">${state.groupMergeName}<select class="form-select form-select-sm" id="${id}replicates" name="replicates"></select></label>
    </div>
    ${state.pasteButtonPosition === pasteButtonPositions.RIGHT ? `<button name="paste" class="btn btn-sm btn-primary" ${state.pasteAllowed ? '' : 'disabled'}>Paste</button>` : ''}
    <div class="reset-button ${state.reset ? `show` : ''}">
        <button name="reset" class="btn btn-sm btn-primary">Reset</button>
    </div>
</div>`;
}

export default class MyassaysParamsGroupTable extends MyassaysComponent {
    static get uniqueId() {
        if (this._uniqueIdCounter === undefined) {
            this._uniqueIdCounter = 1;
        } else {
            this._uniqueIdCounter++;
        }
        return `--myassays-params-group-table-${this._uniqueIdCounter}-`;
    }

    static events = {
        VALUE_CHANGE: 'valuechange',
        VALUE_CHANGE_VALIDATED: 'valuechangevalidated',
        DATA_CHANGE: 'datachange',
        ROW_SELECT: 'rowselect',
        REPLICATES_CHANGE: 'replicateschange',
        ERROR: 'error',
    }

    static errorCodes = ParamsTableView.errorCodes;

    static get propTypes() {
        return {
            id: PropTypes.string,
            nameTitle: PropTypes.string.required
                .comment('Title of left hand column'),
            dataTitle: PropTypes.string.required
                .comment('Title of right hand column'),
            dataType: PropTypes.string.lookup([dataTypes.NUMERIC, dataTypes.STRING]).required
                .comment('Determines the data type of the right hand column only'),
            typeIcons: PropTypes.bool.default(false)
                .comment('When true, shows coloured circles before names'),
            typeColour: PropTypes.string.default('#ffffff')
                .comment('Colour of the icons'),
            groupsMode: PropTypes.string.lookup(groupsModes).default(groupsModes.NORMAL)
                .comment('"ids" is a special use case for editing group name ids'),
            numGroups: PropTypes.number.required.observed
                .comment('Number of rows in the table unless group-merge is true (see below)'),
            groupMerge: PropTypes.bool.default(false)
                .comment('Allows use of replicates. Number of rows in the table = num-groups / replicates'),
            groupMergeName: PropTypes.string.default('Replicates:')
                .comment('The label of the no. of replicates field'),
            seriesEditor: PropTypes.bool.default(false)
                .comment('If true, series editor is included'),
            seriesEditorState: PropTypes.bool.default(false)
                .comment('If true, series editor is open by default'),
            seriesDataBase: PropTypes.number.default(1).observed
                .comment('The start value of the series'),
            seriesDataFactor: PropTypes.number.min(0).default(1).observed
                .comment('The series factor'),
            seriesDataIncrement: PropTypes.number.default(1).observed
                .comment('The series increment (overrides the factor if non-zero)'),
            seriesRepeatEditor: PropTypes.bool.default(false),
            seriesRepeatEditorState: PropTypes.bool.default(false),
            seriesRepeatDefault: PropTypes.number.default(1),
            tipText: PropTypes.string.default(''),
            unitsEditor: PropTypes.bool.default(false),
            unitsValue: PropTypes.string.default(''),
            reset: PropTypes.bool.default(false),
            resetAction: PropTypes.string.default(''),
            decimalSeparator: PropTypes.string.lookup([DECIMAL_SEPARATOR_POINT, DECIMAL_SEPARATOR_COMMA]).default(DECIMAL_SEPARATOR_POINT),
            nameValue: PropTypes.string.default('')
                .comment('A comma separated list, if supplied overrides generated names'),
            nameBase: PropTypes.string.default(NULL_NAME_BASE)
                .comment('If not supplied, the name-title is used, adding a number suffix for each row. A null string can be used to give numbers only'),
            dataValue: PropTypes.string.default('')
                .comment('A comma separated list, if supplied overrides any generated series'),
            dataValidation: PropTypes.string.default(''),
            dataPaddingNumeric: PropTypes.number.default(1)
                .comment('Used when values cannot be derived, e.g. when num-groups is increased and there is no dynamic series'),
            dataPaddingString: PropTypes.string.default('(Unknown)')
                .comment('Used when values cannot be derived, e.g. when num-groups is increased and there is no dynamic series'),
            pasteButtonPosition: PropTypes.string.lookup(pasteButtonPositions).default(pasteButtonPositions.RIGHT)
                .comment('"right" puts the button below the controls on the right of the table.\n"bottom" puts it below the data column'),
        }
    }
    constructor() {
        super();

        // NOTE: all props are copied to state, these are additional state only attributes.
        this._state = new Model({
            pasteAllowed: !!navigator.clipboard.readText,
            groupMergeReplicates: 1,
            replicatesOptions: [],
        });

        this._root = this;

        this._hasBeenRendered = false;
    }

    static get observedAttributes() {
        return PropTypes.getObserved(this.propTypes);
    }

    connectedCallback() {
        const state = PropTypes.attributesToProps(this);

        // if it's a dynamic table, and a data-value is provided, override the series-editor-state to false;
        if (state.seriesEditor && state.seriesEditorState && state.dataValue !== '') {
            state.seriesEditorState = false;
        }

        if (state.groupMerge && state.dataValue) {
            // analyse the data-value to see if we can infer a groupMergeReplicates value
            const values = state.dataValue.trim().split(/ *, */).map(item => state.dataType === dataTypes.NUMERIC ? Number(item) : item);
            const replicatesFound = [1];
            let replicatesFoundIndex = 0;

            let value = values.shift();
            while (values.length > 0) {
                if (values[0] === value) {
                    replicatesFound[replicatesFoundIndex]++;
                } else {
                    replicatesFoundIndex++;
                    replicatesFound.push(1);
                }
                value = values.shift();
            }
            const minReplicates = Math.min(...replicatesFound);
            if (minReplicates > 1 && Math.max(...replicatesFound.map(item => item % minReplicates)) === 0) {
                state.groupMergeReplicates = minReplicates;
            }
        }

        this._state.set(state);

        this._root.innerHTML = template(MyassaysParamsGroupTable.uniqueId, this._state.attributes);
        this.renderParamsTable();
        this.renderSeriesEditor();
        this.addControlListeners();
        this._hasBeenRendered = true;

        this._state.addEventListener('change', evt => {
            this.onStateChange();
        });

        this._state.set({replicateOptions: this.getReplicateOptions(state.numGroups)});

        this._paramsTableView.items = this.getItems({
            initial: true,
        });
    }

    renderParamsTable() {
        const { typeColour } = this._state.attributes;
        this._paramsTableView = ParamsTableView.options
            .rootElement(this._root.querySelector('.params-table'))
            .columns(this.columns)
            .typeColour(typeColour)
            .showPasteButtons(this._state.get('pasteButtonPosition') === pasteButtonPositions.BOTTOM)
            .selectable(true)
            .createInstance();
    }

    renderSeriesEditor() {
        const state = this._state.attributes;
        const open = state.seriesEditorState;
        const increment = state.seriesDataIncrement;
        const factor = state.seriesDataFactor;
        const operation = (() => {
            switch (true) {
                case factor === 0 && increment >= 0:
                    return operations.ADD;
                case factor >= 1 && (increment === 0 || increment === 1):
                    return operations.MULTIPLY;
                case factor < 1 && (increment === 0 || increment === 1):
                    return operations.DIVIDE;
                case increment >= 0:
                    return operations.ADD;
                case increment < 0:
                    return operations.SUBTRACT;
            }
        })();
        const operatorMode = (() => {
            switch (true) {
                case factor === 0 && increment === 0:
                    return operationsModes.BOTH;
                case factor === 0:
                    return operationsModes.ADD_SUBTRACT;
                case increment === 0:
                    return operationsModes.MULTIPLY_DIVIDE;
                default:
                    return operationsModes.BOTH;
            }
        })();
        const addSubtractOperand = (() => {
            switch (true) {
                case factor === 0 && increment >= 0:
                    return increment;
                case factor >= 1 && (increment === 0 || increment === 1):
                    return increment;
                case factor < 1 && (increment === 0 || increment === 1):
                    return increment;
                default:
                    return Math.abs(increment);
            }
        })();
        const multiplyDivideOperand = (() => {
            switch (true) {
                case factor === 0:
                    return 1;
                default:
                    return factor >= 1 ? factor : 1/factor;
            }
        })();
        this._seriesEditorView = new SeriesEditorView(this._root.querySelector('.series-editor-root'), open, operation, operatorMode, addSubtractOperand, multiplyDivideOperand);

        if (state.groupsMode !== groupsModes.IDS && state.seriesEditor) {
            this._root.querySelector(`.series-editor`).classList.add(`show`);
        }
    }

    addControlListeners() {
        const setState = attributes => this._state.set(attributes);
        const toggleState = attrName => {
            setState({[attrName]: !this._state.get(attrName)});
        }
        const addListener = (selector, type, listener) => {
            const element = this._root.querySelector(selector);
            if (element) {
                element.addEventListener(type, listener);
            }
        }

        this._seriesEditorView.addEventListener(SeriesEditorView.events.STATE_CHANGE, this.onSeriesEditorStateChange);

        this._paramsTableView.addEventListener(ParamsTableView.events.STATE_CHANGE, this.onParamsTableStateChange);
        this._paramsTableView.addEventListener(ParamsTableView.events.ERROR, this.onParamsTableError);

        addListener('[name="units"]', 'change', this.onUnitsChange);
        addListener('[name="replicates"]', 'change', this.onReplicatesChange);
        addListener('[name="open-series-repeat-editor"]', 'change', evt => {
            Utils.doAndResizeSmoothly(this._root.querySelector('.series-repeat-editor'), () => {
                setState({seriesRepeatEditorState: evt.target.checked});
            });
        });
        addListener('[name="paste"]', 'focus', this.onPasteFocus);
        addListener('[name="paste"]', 'click', this.onPasteClick);
        addListener('[name="reset"]', 'click', this.onResetClick);
    }

    onUnitsChange = evt => {

    }

    onReplicatesChange = evt => {
        Utils.doAndResizeSmoothly(this._root.querySelector('.params-table'), () => {
            this._state.set({groupMergeReplicates: Number(evt.target.value)});
        });
    }

    onSeriesEditorStateChange = evt => {
        const model = evt.detail;
        model.hasChanged('open', newValue => {
            this._state.set({seriesEditorState: newValue});
            newValue && this.updateItems();
        });
        model.hasChanged('addSubtractOperand', newValue => {
            this.updateItems();
        });
        model.hasChanged('multiplyDivideOperand', newValue => {
            this.updateItems();
        });
        model.hasChanged('operation', newValue => {
            this.updateItems();
        });
    }

    onParamsTableStateChange = evt => {
        const { seriesEditor, seriesEditorState, seriesDataBase } = this._state.attributes;
        const model = evt.detail;
        model.hasChanged('items', (newValue, previousValue) => {
            delete this._oldFixedData;
            if (seriesEditor && seriesEditorState) {
                if (seriesDataBase !== newValue[0].data) {
                    this._state.set({seriesDataBase: newValue[0].data});
                    // this triggers a re-calc of the series
                    return;
                }
            }
            if (previousValue && previousValue.length > 0) {
                this.dispatchValueChangeEvent();
            } else {
                setTimeout(() => this.dispatchValueChangeEvent(), 1);
            }
            const previousData = (previousValue ? previousValue.map(item => item.data).join(',') : '');
            const newData = newValue.map(item => item.data).join(',');
            if (newData !== previousData) {
                if (previousData === '') {
                    setTimeout(() => {
                        this.dispatchDataChangeEvent();
                        if (this._state.get('groupMergeReplicates') > 1) {
                            this.dispatchReplicatesChangeEvent();
                        }
                    }, 1);
                } else {
                    this.dispatchDataChangeEvent();
                }
            }
        });
        model.hasChanged('selectedRowIndex', (newValue) => {
            this.dispatchRowSelectEvent(newValue);
        });
    }

    onParamsTableError = evt => {
        evt.preventDefault();
        const error = evt.detail;
        const event = new BlazorCompatibleEvent(MyassaysParamsGroupTable.events.ERROR, {
            detail: error,
            cancelable: true,
        });
        this.dispatchEvent(event) && alert(error.message);
    }

    onStateChange(force) {
        // if no event is passed, assume all has changed
        const hasChanged = (name, handler) => {
            force ? handler(this._state.get(name)) : this._state.hasChanged(name, handler);
        }
        const toggleClass= (selector, className, force) => {
            this._root.querySelector(selector).classList.toggle(className, force);
        }
        hasChanged('seriesEditorState', newValue => {
            this._paramsTableView.columns = this.columns;
            if (newValue) {
                const oldFixedData = this._paramsTableView.items.map(item => item.data);
                setTimeout(() => {
                    this._oldFixedData = oldFixedData;
                }, 1);
            } else {
                if (this._oldFixedData) {
                    this._paramsTableView.items = this._paramsTableView.items.map((item, i) => ({...item, data: this._oldFixedData[i]}))
                }
            }
        });
        hasChanged('seriesDataBase', newValue => {
            this._paramsTableView.items = this.getItems();
        });
        hasChanged('seriesRepeatEditorState', newValue => {
            this.updateItems();
        });
        hasChanged('numGroups', (newValue, previousValue) => {
            const items = this._paramsTableView.items;
            const { seriesEditor, seriesEditorState, groupsMode, groupMerge, groupMergeReplicates, dataType, dataValue, dataPaddingNumeric, dataPaddingString } = this._state.attributes;
            let newItems = [];
            const stateOverride = {groupMergeReplicates};
            if (groupMerge && (newValue % groupMergeReplicates !== 0)) {
                stateOverride.groupMergeReplicates = 1;
            }
            if (previousValue !== undefined) {
                if (newValue < previousValue && groupMergeReplicates === stateOverride.groupMergeReplicates) {
                    newItems = items.slice(0, newValue/groupMergeReplicates);
                } else if (seriesEditor && seriesEditorState) {
                    newItems = this.getItems({stateOverride});
                } else {
                    const dataValues = (dataValue === '') ? [] : dataValue.trim().split(/ *, */).map(item => dataType === dataTypes.NUMERIC ? Number(item) : item);

                    newItems = this.getItems({stateOverride}).map((item, i) => {
                        if (items[i] !== undefined) {
                            item.data = items[i].data;
                        } else {
                            if (groupsMode !== groupsModes.IDS) {
                                item.data = dataValues[i * stateOverride.groupMergeReplicates] !== undefined ? dataValues[i * stateOverride.groupMergeReplicates] : (dataType === dataTypes.NUMERIC ? dataPaddingNumeric : dataPaddingString);
                            }
                        }
                        return item;
                    });
                }
                this._paramsTableView.items = newItems;
                this._state.set({...stateOverride, replicateOptions: this.getReplicateOptions(newValue)});
            }
        });
        hasChanged('replicateOptions', newValue => {
            const { groupMergeReplicates } = this._state.attributes;
            this._root.querySelector('[name="replicates"]').innerHTML = newValue.map(item => `<option value="${item}" ${item === groupMergeReplicates ? 'selected' : ''}>${item}</option>`).join('');
        });
        hasChanged('groupMergeReplicates', (newValue, previousValue) => {
            const { seriesEditor, seriesEditorState, numGroups } = this._state.attributes;
            const items = this._paramsTableView.items;
            let newItems = [];
            if (newValue > previousValue) {
                newItems = items.slice(0, numGroups/newValue);
            } else if (seriesEditor && seriesEditorState) {
                newItems = this.getItems();
            } else {
                newItems = this.getItems().map((item, i) => {
                    if (items[i] !== undefined) {
                        item.data = items[i].data;
                    }
                    return item;
                });
            }
            this._paramsTableView.items = newItems;
            this._root.querySelector('[name="replicates"]').value = newValue;
            this.dispatchReplicatesChangeEvent();
        });
    }

    getReplicateOptions(numGroups) {
        const replicateOptions = [];

        for (let i = 1; i <= numGroups/2; i++) {
            if (numGroups % i === 0) {
                replicateOptions.push(i);
            }
        }

        return replicateOptions;
    }

    dispatchValueChangeEvent() {
        this.dispatchEvent(new BlazorCompatibleEvent(MyassaysParamsGroupTable.events.VALUE_CHANGE));
    }

    dispatchReplicatesChangeEvent() {
        this.dispatchEvent(new BlazorCompatibleEvent(MyassaysParamsGroupTable.events.REPLICATES_CHANGE, {
            detail: this.groupMergeReplicates,
        }));
    }

    dispatchValueChangeValidatedEvent() {
        this.dispatchEvent(new BlazorCompatibleEvent(MyassaysParamsGroupTable.events.VALUE_CHANGE_VALIDATED));
    }

    dispatchDataChangeEvent() {
        this.dispatchEvent(new BlazorCompatibleEvent(MyassaysParamsGroupTable.events.DATA_CHANGE, {
            detail: this.dataValue,
        }));
    }

    dispatchRowSelectEvent(rowIndex) {
        this.dispatchEvent(new BlazorCompatibleEvent(MyassaysParamsGroupTable.events.ROW_SELECT, {
            detail: rowIndex,
        }));
    }

    get columns() {
        const { nameTitle, dataTitle, dataType, typeIcons, groupsMode, seriesEditor, seriesEditorState } = this._state.attributes;
        const editMode = (seriesEditor && seriesEditorState) ? editModes.FIRST_CELL : editModes.ALL;
        return [
            {name: 'name', heading: nameTitle, dataType: typeIcons ? dataTypes.LABEL_WITH_TYPE : dataTypes.LABEL, editMode: editModes.NONE},
            {name: 'data', heading: dataTitle, dataType, editMode},
        ];
    }

    get nameValue() {
        const { numGroups, groupMerge, groupMergeReplicates } = this._state.attributes;

        const replicates = groupMerge ? groupMergeReplicates : 1;
        const totalItems = numGroups / replicates;

        const items = this._paramsTableView.items;

        const nameArray = [];
        for (let i = 0; i < totalItems; i++) {
            const item = items[i];
            const name = `"${item.name}"`;
            for (let r = 0; r < replicates; r++) {
                nameArray.push(name);
            }
        }

        return nameArray.join(',');
    }

    set nameValue(newValue) {
        const oldItems = this._paramsTableView.items;
        const mapper = name => name.replace(/^"?(.*?)"?$/, '$1');
        const newNames = newValue.trim().split(/ *, */).map(mapper);
        this._paramsTableView.items = newNames.map((name, i) => {
            const data = oldItems[i] ? oldItems[i].data : '';
            return {name, data};
        });
    }

    get dataValue() {
        const { dataType, numGroups, groupMerge, groupMergeReplicates } = this._state.attributes;

        const replicates = groupMerge ? groupMergeReplicates : 1;
        const totalItems = numGroups / replicates;

        const items = this._paramsTableView.items;

        const dataArray = [];
        for (let i = 0; i < totalItems; i++) {
            const item = items[i];
            const data = dataType === dataTypes.STRING ? `"${item.data}"` : item.data;
            for (let r = 0; r < replicates; r++) {
                dataArray.push(data);
            }
        }

        return dataArray.join(',');
    }

    set dataValue(newValue) {
        const { groupsMode, dataType, seriesEditor } = this._state.attributes;
        const mapper = dataType === dataTypes.NUMERIC ? data => Number(data) : data => data.replace(/^"?(.*?)"?$/, '$1');
        const newData = newValue.trim().split(/ *, */).map(mapper);
        if (groupsMode !== groupsModes.IDS && dataType === dataTypes.NUMERIC && seriesEditor) {
            if (newData.length === 1) {
                this._state.set({seriesDataBase: newData[0]});
            } else if(newData.length > 2) {
                const diff1 = newData[1] - newData[2];
                const diff2 = newData[2] - newData[1];

                if (diff1 === diff2) {
                    this._state.set({
                        seriesDataOperation: diff1 > 0 ? operations.ADD : operations.SUBTRACT,
                        seriesDataIncrement: Math.abs(diff1),
                    });
                } else {
                    const factor = newData[1] / newData[0];
                    if (factor >= 1) {
                        this._state.set({
                            seriesDataOperation: operations.MULTIPLY,
                            seriesDataFactor: factor,
                        });
                    } else {
                        this._state.set({
                            seriesDataOperation: operations.DIVIDE,
                            seriesDataFactor: 1/factor,
                        });
                    }
                }
            }
        } else {
            const oldItems = this._paramsTableView.items;
            this._paramsTableView.items = newData.map((data, i) => {
                const name = oldItems[i] ? oldItems[i].name : (dataType === dataTypes.STRING || groupsMode === groupsModes.IDS ? '' : 0);
                return {name, data};
            });
        }
    }

    get groupMergeReplicates() {
        return this._state.get('groupMergeReplicates');
    }

    onPasteFocus = evt => {
        this._paramsTableView.onPasteFocus(evt);
    }

    onPasteClick = evt => {
        this._paramsTableView.pasteIntoColumn('data');
    }

    onResetClick = evt => {
        if (this._state.get('groupsMode') === groupsModes.IDS) {
            this.updateItems();
        }
    }

    updateItems() {
        this._paramsTableView.items = this.getItems();
    }

    getItems(options = {}) {
        const initial = !!options.initial;
        const stateOverride = options.stateOverride || {};

        const { groupsMode, dataType, seriesDataBase, nameTitle, nameBase, nameValue, dataValue, numGroups, groupMerge, groupMergeReplicates, seriesEditor, dataPaddingNumeric, dataPaddingString } = {...this._state.attributes, ...stateOverride};
        const { operation, addSubtractOperand, multiplyDivideOperand } = this._seriesEditorView.state;

        const items = [];

        const nameValues = (nameValue === '') ? [] : nameValue.trim().split(/ *, */);
        const dataValues = (dataValue === '') ? [] : dataValue.trim().split(/ *, */).map(item => dataType === dataTypes.NUMERIC ? Number(item) : item);

        const replicates = groupMerge ? groupMergeReplicates : 1;
        const totalItems = numGroups / replicates;

        let seriesData = seriesDataBase;
        for (let i = 0; i < totalItems; i++) {
            const dataRow = i * replicates;
            const name = nameValues[dataRow] !== undefined ? nameValues[dataRow] : (nameBase !== NULL_NAME_BASE ? nameBase : nameTitle) + (i + 1);
            if (groupsMode === groupsModes.IDS) {
                items.push({name, data: name});
            } else {
                let data;
                if (seriesEditor && !(initial && dataValue !== '')) {
                    items.push({name, data: seriesData});
                    switch (operation) {
                        case operations.ADD: seriesData += addSubtractOperand; break;
                        case operations.SUBTRACT: seriesData -= addSubtractOperand; break;
                        case operations.MULTIPLY: seriesData *= multiplyDivideOperand; break;
                        case operations.DIVIDE: seriesData /= multiplyDivideOperand; break;
                    }
                } else {
                    data = dataValues[dataRow] !== undefined ? dataValues[dataRow] : (dataType === dataTypes.NUMERIC ? dataPaddingNumeric : dataPaddingString);
                    items.push({name, data});
                }
            }
        }

        return items;
    }

    attributeChangedCallback(attrName, oldVal, newVal) {
        if (this._hasBeenRendered) {
             this._state.set(PropTypes.attributesToProps(this, attrName));
        }
    }

}
